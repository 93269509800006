<script>
import { useAuthStore } from "@/stores/auth";
import router from "@/router/index.js";
export default {
  name: "App",
  components: {},

  data() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  methods: {
    logout() {
      this.authStore.logout();
      router.replace({ path: "/" });
    },
  },
};
</script>

<template>
  <!-- Fixed navbar -->
  <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/contacts">
        <img width="40px" class="mr-4" src="./assets/logo.png" />
        USPA ClientConnect
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item" v-if="authStore.isLoggedIn">
            <a class="nav-link" href="/contacts">contacts</a>
          </li>
          <li class="nav-item" v-if="authStore.isRoleAdmin">
            <a class="nav-link" href="/users">users</a>
          </li>
          <li class="nav-item" v-if="authStore.isRoleAdmin">
            <a class="nav-link" href="/audits">audit history</a>
          </li>
        </ul>

        <div v-if="authStore.isLoggedIn" class="d-flex">
          <ul class="navbar-nav me-auto">
            <li class="nav-item mx-4 nav-link">
              Welcome {{ authStore.user.first_name }}
              <font-awesome-icon
                class="hand ml-2"
                title="Logout"
                icon="fa-arrow-right-from-bracket"
                @click="logout"
              />
            </li>
            <li class="nav-item mx-4"></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <!-- Begin page content -->
  <div class="container container-main">
    <router-view />
  </div>

  <div class="footer mt-auto py-5 bg-light">
    <div class="container">
      <span class="text-muted"></span>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container-main {
  margin-top: 100px;
  margin-bottom: 100px;
}
.footer {
  height: 300px;
}
.mr-3 {
  margin-right: 1rem;
}
.ml-3 {
  margin-left: 1rem;
}
.hand {
  cursor: pointer;
}
.svg-inline--fa {
  color: #0d6efd;
}
</style>
