<script>
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import helpers from "@/helpers";

export default {
  data() {
    const authStore = useAuthStore();
    return {
      authStore,

      successMessage: "",
      errorMessage: "",

      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,

      filters: {
        user_id: authStore.user.id,
        sort_column: "created_at", // Tracks the column being sorted
        sort_order: "desc", // Tracks the sorting direction ('asc' or 'desc')
      },
    };
  },
  mounted() {
    this.getAllResultList(1);
  },
  methods: {
    clearMessages() {
      this.successMessage = false;
      this.errorMessage = false;
    },
    getSortClass(column) {
      return helpers.getHelperSortClass(column, this.filters);
    },
    sortBy(column) {
      this.paginationCurrentPage = 1;
      if (this.filters.sort_column === column) {
        this.filters.sort_order =
          this.filters.sort_order === "asc" ? "desc" : "asc";
      } else {
        this.filters.sort_column = column;
        this.filters.sort_order = "asc";
      }
      this.resultList = [];
      this.getAllResultList(this.paginationCurrentPage);
    },
    processPagination(page) {
      this.getAllResultList(page);
    },
    getAllResultList(page) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/audits?page_number=" + page,
          this.filters,
          {
            headers: { api_key: this.authStore.user.api_key },
          }
        )
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <h2>Audit History</h2>
  </div>

  <div class="pagination-container">
    <vue-awesome-paginate
      @click="processPagination"
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th
          width="15%"
          :class="getSortClass('created_at')"
          @click="sortBy('created_at')"
        >
          CreatedAt
        </th>
        <th
          width="20%"
          :class="getSortClass('last_name')"
          @click="sortBy('last_name')"
        >
          User
        </th>
        <th
          width="15%"
          :class="getSortClass('table_name')"
          @click="sortBy('table_name')"
        >
          Table
        </th>
        <th
          width="15%"
          :class="getSortClass('action')"
          @click="sortBy('action')"
        >
          Action
        </th>
        <th width="35%" class="">Data</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="audit_history in resultList"
        :key="audit_history.id"
        class="hand"
      >
        <td>{{ audit_history.created_at }}</td>
        <td>{{ audit_history.first_name }} {{ audit_history.last_name }}</td>
        <td>{{ audit_history.table_name }}</td>
        <td>{{ audit_history.action }}</td>
        <td>{{ audit_history.object_values }}</td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped></style>
