import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: useLocalStorage("authStore", []),
    }),
    actions: {
        login(user) {
            this.user = user
        },

        logout() {
            this.user = null;
            //router.push({ path: 'login' })
        },
    },
    getters: {
        isLoggedIn() {
            return this.user.id > 0;
        },
        isRoleAdmin() {
            return this.user.role == 'admin';
        }
    }
})