<script>
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import helpers from "@/helpers";

export default {
  data() {
    const authStore = useAuthStore();
    return {
      authStore,
      editMode: false,
      drawer: null,
      user: { role: "user" },

      alertMessage: "",
      showErrorAlert: false,
      showSuccessAlert: false,

      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,

      filters: {
        user_id: authStore.user.id,
        sort_column: "users.first_name", // Tracks the column being sorted
        sort_order: "asc", // Tracks the sorting direction ('asc' or 'desc')
      },
      roleList: [
        ["admin", "admin"],
        ["basic", "basic"],
      ],
    };
  },
  mounted() {
    this.drawer = new window.bootstrap.Offcanvas(
      document.getElementById("drawerDiv")
    );

    this.getAllResultList(1);
  },
  methods: {
    clearMessages() {
      this.showErrorAlert = false;
      this.showSuccessAlert = false;
      this.alertMessage = "";
    },
    getSortClass(column) {
      return helpers.getHelperSortClass(column, this.filters);
    },
    sortBy(column) {
      this.paginationCurrentPage = 1;
      if (this.filters.sort_column === column) {
        this.filters.sort_order =
          this.filters.sort_order === "asc" ? "desc" : "asc";
      } else {
        this.filters.sort_column = column;
        this.filters.sort_order = "asc";
      }
      this.resultList = [];
      this.getAllResultList(this.paginationCurrentPage);
    },
    processPagination(page) {
      this.getAllResultList(page);
    },
    getAllResultList(page) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/users?page_number=" + page,
          this.filters,
          {
            headers: { api_key: this.authStore.user.api_key },
          }
        )
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
    getUser(id) {
      this.clearMessages();
      this.editMode = false;
      axios
        .get(process.env.VUE_APP_API_URL + "/users/" + id, {
          headers: { api_key: this.authStore.user.api_key },
        })
        .then((response) => {
          this.user = response.data;
          this.showOffcanvas();
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
    showOffcanvas() {
      this.drawer.show();
    },
    addUser() {
      this.clearMessages();
      this.user = { role: "user" };
      this.editMode = true;
      this.showOffcanvas();
    },
    editUser() {
      this.clearMessages();
      this.editMode = true;
    },
    viewUser() {
      this.clearMessages();
      // if adding a new contact, close the offcanvas
      if (!this.user.id) {
        this.drawer.hide();
      } else {
        this.editMode = false;
      }
    },
    saveUser() {
      this.showErrorAlert = false;
      this.showSuccessAlert = false;
      axios
        .post(process.env.VUE_APP_API_URL + "/users-save", this.user, {
          headers: { api_key: this.authStore.user.api_key },
        })
        .then((response) => {
          this.showSuccessAlert = true;
          this.alertMessage = "Data saved";
          this.editMode = false;
          this.user = response.data;
        })
        .catch((error) => {
          this.alertMessage = helpers.getErrorMessage(error);

          this.showErrorAlert = true;
          this.editMode = true;
        });
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <h2>Users</h2>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" @click="addUser">
        <i class="bi bi-plus-circle"></i>
        Add
      </button>
    </div>
  </div>

  <div class="pagination-container">
    <vue-awesome-paginate
      @click="processPagination"
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th
          width="25%"
          :class="getSortClass('users.first_name')"
          @click="sortBy('users.first_name')"
        >
          First Name
        </th>
        <th
          width="25%"
          :class="getSortClass('users.last_name')"
          @click="sortBy('users.last_name')"
        >
          Last Name
        </th>
        <th
          width="30%"
          :class="getSortClass('users.email')"
          @click="sortBy('users.email')"
        >
          Email
        </th>
        <th
          width="20%"
          :class="getSortClass('users.role')"
          @click="sortBy('users.role')"
        >
          Role
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="user in resultList"
        :key="user.id"
        class="hand"
        @click="getUser(user.id)"
      >
        <td>{{ user.first_name }}</td>
        <td>{{ user.last_name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.role }}</td>
      </tr>
    </tbody>
  </table>

  <!-- drawer ********************************************************** -->
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="drawerDiv"
    aria-labelledby="drawerDivLabel"
    data-bs-keyboard="false"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="drawerDivLabel">History</h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body">
      <div class="d-flex align-items-center mb-3">
        <div class="ms-auto">
          <div v-if="editMode">
            <button class="btn btn-light btn-sm mx-3" @click="viewUser">
              Cancel
            </button>
            <button class="btn btn-primary btn-sm" @click="saveUser">
              Save
            </button>
          </div>
          <div v-else>
            <button class="btn btn-primary btn-sm" @click="editUser">
              Edit
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="showErrorAlert"
        class="alert alert-danger mb-3"
        role="alert"
        v-html="alertMessage"
      ></div>
      <div
        v-if="showSuccessAlert"
        class="alert alert-success mb-3"
        role="alert"
        v-html="alertMessage"
      ></div>

      <!-- form ************************************************************************************* -->
      <form>
        <div class="card mb-3">
          <div class="card-body">
            <div class="mb-3">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">First Name</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="user.first_name"
                    />
                  </div>
                  <div v-else>{{ user.first_name }}</div>
                </div>
                <div class="col-6">
                  <label class="form-label">Last Name</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="user.last_name"
                    />
                  </div>
                  <div v-else>{{ user.last_name }}</div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">Password</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="user.password"
                    />
                  </div>
                  <div v-else>*************</div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">Email</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="user.email"
                    />
                  </div>
                  <div v-else>{{ user.email }}</div>
                </div>
                <div class="col-6">
                  <label class="form-label">Role</label>
                  <div v-if="editMode">
                    <select
                      v-model="user.role"
                      class="form-select form-select-sm"
                    >
                      <option
                        v-for="role in roleList"
                        :key="role[1]"
                        :value="role[0]"
                      >
                        {{ role[0] }}
                      </option>
                    </select>
                  </div>
                  <div v-else>{{ user.role }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
