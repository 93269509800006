import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'

import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInfoCircle, faArrowRightFromBracket, faCartShopping, faCopy, faClockRotateLeft, faEye, faEyeSlash, faFilter, faPen, faQuestion, faStar, faTrashAlt, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowRightFromBracket)
library.add(faCartShopping)
library.add(faClockRotateLeft)
library.add(faCopy)
library.add(faEye)
library.add(faEyeSlash)
library.add(faFilter)
library.add(faInfoCircle)
library.add(faPen)
library.add(faStar)
library.add(faQuestion)
library.add(faTrashAlt)
library.add(faUser)
library.add(faUserPlus)


// Pagination
import VueAwesomePaginate from 'vue-awesome-paginate';
import 'vue-awesome-paginate/dist/style.css';
// custom CSS
import './assets/base.css';

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(createPinia())
app.use(VueAwesomePaginate);
app.mount('#app')