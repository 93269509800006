<script>
import { useAuthStore } from "@/stores/auth";
import router from "@/router/index.js";
import { ref } from "vue";
import axios from "axios";

export default {
  data() {
    const authStore = useAuthStore();
    const email = ref("");
    const password = ref("");

    return {
      showAlert: false,
      alertMessage: "",
      showPassword: false,
      email,
      password,
      authStore,
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async adminLogin() {
      let data = { email: this.email, password: this.password };
      await axios
        .post(process.env.VUE_APP_API_URL + "/login", data)
        .then((response) => {
          this.authStore.login(response.data);

          router.replace({ path: "/contacts" });
        })
        .catch((error) => {
          this.showAlert = true;
          this.alertMessage = error.message;
          console.log(error.message);
        });
    },
  },
};
</script>

<template>
  <div class="card login_form">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
    <div v-if="showAlert" class="alert alert-danger" role="alert">
      Login failed. Please try again.
    </div>

    <div class="mb-3">
      <label for="exampleInputemail1" class="form-label">Email</label>
      <input v-model="email" type="text" class="form-control" placeholder="" />
    </div>

    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Password</label>

      <div class="input-group mb-3">
        <input
          v-if="showPassword"
          v-model="password"
          type="text"
          class="form-control"
        />
        <input v-else v-model="password" type="password" class="form-control" />

        <div class="input-group-text">
          <span @click="toggleShow">
            <font-awesome-icon v-if="showPassword" icon="fa-eye-slash" />
            <font-awesome-icon v-else icon="fa-eye" />
          </span>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" @click="adminLogin">Login</button>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
