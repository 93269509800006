import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth';
import AuditList from '@/views/AuditList.vue';
import ContactList from '@/views/ContactList.vue';
import LoginView from '@/views/LoginView.vue';
import UserList from '@/views/UserList.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { requiresAdmin: false, requiresAuth: false }
  },
  {
    path: '/audits',
    name: 'audits',
    component: AuditList,
    meta: { requiresAdmin: true, requiresAuth: true }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactList,
    meta: { requiresAdmin: false, requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: UserList,
    meta: { requiresAdmin: true, requiresAuth: true }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to) => {
  const authStore = useAuthStore();

  // this route requires auth, check if logged in, redirect to login page.
  if (to.meta.requiresAuth && to.path != "/" && !authStore.isLoggedIn) {
    return {
      path: '/',
    }
  }
  // this route requires auth, check if logged in, redirect to login page.
  if (to.meta.requiresAdmin && to.path != "/" && !authStore.isRoleAdmin) {
    return {
      path: '/',
    }
  }
});


export default router
