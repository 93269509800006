<script>
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import helpers from "@/helpers";
import dayjs from "dayjs";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: { flatPickr },
  data() {
    let begin_date = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    let end_date = new Date();
    const authStore = useAuthStore();
    return {
      begin_date: begin_date,
      end_date: end_date,
      authStore,
      helpers: helpers,
      isLoading: false,
      contact: {
        origin: "Ipsos in US",
      },
      editMode: false,
      drawer: null,

      industrySelect: "",
      showIndustryInput: true,
      mainErrorMessage: "",
      successMessage: "",
      errorMessage: "",
      duplicateRecord: null,

      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,

      classEmail: "form-control form-control-sm",
      classCompany: "form-control form-control-sm",
      classFirstName: "form-control form-control-sm",
      classLastName: "form-control form-control-sm",
      classOrigin: "form-control form-control-sm",

      filters: {
        user_id: authStore.user_id,
        keyword: "",
        updated_at_begin: dayjs(begin_date).format("YYYY-MM-DD"),
        updated_at_end: dayjs(end_date).format("YYYY-MM-DD"),
        sort_column: "contacts.industry", // Tracks the column being sorted
        sort_order: "asc", // Tracks the sorting direction ('asc' or 'desc')
      },
    };
  },
  mounted() {
    this.drawer = new window.bootstrap.Offcanvas(
      document.getElementById("drawerDiv")
    );

    this.getAllResultList(1);
  },
  methods: {
    getFormattedDate(date) {
      if (date) {
        return dayjs(date).format("YYYY-MM-DD h:mma");
        // return dayjs.tz(date, "America/New_York").format("YYYY-MM-DD h:mma");
      }
    },
    isEmailValid(email) {
      if (!email || email.length === 0) {
        return true;
      }
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validate() {
      this.classEmail = this.isEmailValid(this.contact.email)
        ? "form-control form-control-sm"
        : "form-control form-control-sm is-invalid";
      this.classCompany = this.contact.company
        ? "form-control form-control-sm"
        : "form-control form-control-sm is-invalid";
      this.classFirstName = this.contact.first_name
        ? "form-control form-control-sm"
        : "form-control form-control-sm is-invalid";
      this.classLastName = this.contact.last_name
        ? "form-control form-control-sm"
        : "form-control form-control-sm is-invalid";
      this.classOrigin = this.contact.origin
        ? "form-control form-control-sm"
        : "form-control form-control-sm is-invalid";
      if (
        this.isEmailValid(this.contact.email) &&
        this.contact.company &&
        this.contact.first_name &&
        this.contact.last_name &&
        this.contact.origin
      ) {
        return true;
      }

      this.errorMessage = "Please fill in all required fields";
      return false;
    },
    showMainError(message) {
      this.mainErrorMessage = message;
    },
    clearMessages() {
      this.mainErrorMessage = "";
      this.errorMessage = "";
      this.successMessage = "";
      this.duplicateRecord = null;
    },
    getSortClass(column) {
      return helpers.getHelperSortClass(column, this.filters);
    },
    sortBy(column) {
      this.paginationCurrentPage = 1;
      if (this.filters.sort_column === column) {
        this.filters.sort_order =
          this.filters.sort_order === "asc" ? "desc" : "asc";
      } else {
        this.filters.sort_column = column;
        this.filters.sort_order = "asc";
      }
      this.resultList = [];
      this.getAllResultList(this.paginationCurrentPage);
    },
    processPagination(page) {
      this.getAllResultList(page);
    },
    getAllResultList(page) {
      this.clearMessages();
      axios
        .post(
          process.env.VUE_APP_API_URL + "/contacts?page_number=" + page,
          this.filters,
          {
            headers: { api_key: this.authStore.user.api_key },
          }
        )
        .then((response) => {
          this.resultList = response.data.items;
          this.paginationTotalRecords = response.data.total;
          this.paginationMaxRecordsPerPage = response.data.page_size;
          this.paginationCurrentPage = response.data.page_number;
        })
        .catch((error) => {
          this.showMainError(error.message);
        });
    },
    getContact(id) {
      this.clearMessages();
      this.editMode = false;
      axios
        .get(process.env.VUE_APP_API_URL + "/contacts/" + id, {
          headers: { api_key: this.authStore.user.api_key },
        })
        .then((response) => {
          this.contact = response.data;
          this.industrySelect = this.contact.industry;
          this.industrySelectChange();
          this.showOffcanvas();
        })
        .catch(function (error) {
          this.showMainError(error.message);
        });
    },
    industrySelectChange() {
      this.contact.industry = this.industrySelect;

      // if in list, use it, otherwise use the input
      if (helpers.getIndustries().includes(this.contact.industry)) {
        this.showIndustryInput = false;
      } else {
        this.showIndustryInput = true;
      }
    },
    showOffcanvas() {
      this.drawer.show();
    },
    addContact() {
      this.clearMessages();
      this.contact = {
        origin: "Ipsos in US",
      };
      this.editMode = true;
      this.showOffcanvas();
    },
    editContact() {
      this.clearMessages();
      this.editMode = true;
    },
    deleteContact() {
      if (confirm("Do you really want to delete?")) {
        axios
          .delete(
            process.env.VUE_APP_API_URL + "/contacts/" + this.contact.id,
            {
              headers: { api_key: this.authStore.user.api_key },
            }
          )
          .then(() => {
            this.processPagination(this.paginationCurrentPage);
            this.drawer.hide();
          })
          .catch((error) => {
            this.errorMessage = helpers.getErrorMessage(error);
          });
      }
    },
    viewContact() {
      this.clearMessages();
      // if adding a new contact, close the offcanvas
      if (!this.contact.id) {
        this.drawer.hide();
      } else {
        this.editMode = false;
      }
    },
    saveContact(overrideDuplicate) {
      this.clearMessages();
      if (!this.validate()) {
        return;
      }
      this.isLoading = true;
      this.contact.override_duplicate = overrideDuplicate;
      axios
        .post(process.env.VUE_APP_API_URL + "/contacts-save", this.contact, {
          headers: { api_key: this.authStore.user.api_key },
        })
        .then((response) => {
          this.successMessage = "Data saved";
          this.editMode = false;
          this.contact = response.data;
        })
        .catch((error) => {
          this.errorMessage = helpers.getErrorMessage(error);
          this.duplicateRecord = this.getDuplicateRecord(this.errorMessage);
          if (this.duplicateRecord) {
            this.errorMessage = "";
            return;
          }

          this.editMode = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDuplicateRecord(errorMessage) {
      if (errorMessage.includes("Duplicate contact found:")) {
        errorMessage = errorMessage.replace("Duplicate contact found:", "");
        const jsonStringWithDoubleQuotes = errorMessage.replace(/'/g, '"');
        return JSON.parse(jsonStringWithDoubleQuotes);
      }
    },
    getAllContacts() {
      return axios
        .post(
          process.env.VUE_APP_API_URL + "/contacts?page_size=10000",
          this.filters,
          {
            headers: { api_key: this.authStore.user.api_key },
          }
        )
        .then((response) => {
          console.log(response.data.items);
          return response.data.items;
        })
        .catch((error) => {
          console.error("Error fetching contacts:", error);
          return [];
        });
    },
    async downloadCSVData() {
      // down load all results- no pagination
      let results = await this.getAllContacts();
      if (results) {
        let fields = Object.keys(results[0]);
        let replacer = function (key, value) {
          return value === null ? "" : value;
        };
        let csv = results.map(function (row) {
          return fields
            .map(function (fieldName) {
              return JSON.stringify(row[fieldName], replacer);
            })
            .join(",");
        });
        csv.unshift(fields.join(",")); // add header column
        csv = csv.join("\r\n");

        let anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        anchor.target = "_blank";
        anchor.download = "ClientConnect-contacts.csv";
        anchor.click();
      }
    },
    clearFilter() {
      this.clearMessages();
      this.filters.keyword = "";
      this.filters.updated_at_begin = dayjs(this.begin_date).format(
        "YYYY-MM-DD"
      );
      this.filters.updated_at_end = dayjs(this.end_date).format("YYYY-MM-DD");
      this.getAllResultList(1); // reload results
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <h2>Contacts</h2>
    <div class="ms-auto">
      <button class="btn btn-secondary btn-sm me-3" @click="downloadCSVData">
        <i class="bi bi-cloud-download"></i>
        Download
      </button>
      <button class="btn btn-primary btn-sm" @click="addContact">
        <i class="bi bi-plus-circle"></i>
        Add
      </button>
    </div>
  </div>

  <!-- filter ********************************************************** -->

  <div class="filter-container">
    <div class="mb-2">
      <div class="filter-label">Filter</div>
      <input
        v-model="filters.keyword"
        class="form-control form-control form-control-sm d-inline w-75"
        placeholder=""
      />
    </div>

    <div class="mb-2">
      <div class="filter-label">Updated At</div>
      <flatPickr
        v-model="filters.updated_at_begin"
        class="form-control form-control-date flatpickr-input mr-10 d-inline"
        data-bs-focus="false"
      />
      to
      <flatPickr
        v-model="filters.updated_at_end"
        class="form-control form-control-date flatpickr-input ml-10 d-inline"
        data-bs-focus="false"
      />
    </div>

    <div class="">
      <div class="filter-label"></div>
      <button class="btn btn-primary btn-sm" @click="getAllResultList(1)">
        <i class="bi bi-funnel"></i> filter
      </button>
      <button class="btn btn-light btn-sm mx-3" @click="clearFilter">
        clear
      </button>
    </div>
  </div>
  <!-- filter ********************************************************** -->

  <div
    v-if="mainErrorMessage.length > 0"
    class="alert alert-danger mb-3"
    role="alert"
    v-html="mainErrorMessage"
  ></div>

  <!-- pagination ********************************************************** -->
  <div class="pagination-container" v-if="paginationTotalRecords > 0">
    <vue-awesome-paginate
      @click="processPagination"
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
    />
  </div>
  <!-- pagination ********************************************************** -->

  <!-- results ********************************************************** -->
  <table class="table table-hover table-sm mt-3">
    <thead class="table-light">
      <tr>
        <th
          width="20%"
          :class="getSortClass('contacts.industry')"
          @click="sortBy('contacts.industry')"
        >
          Industry
        </th>
        <th
          width="20%"
          class="hand"
          @click="sortBy('contacts.company')"
          :class="getSortClass('contacts.company')"
        >
          Company
        </th>
        <th
          width="20%"
          class="hand"
          @click="sortBy('contacts.last_name')"
          :class="getSortClass('contacts.last_name')"
        >
          Name
        </th>
        <th
          width="20%"
          class="hand"
          @click="sortBy('contacts.city')"
          :class="getSortClass('contacts.city')"
        >
          Location
        </th>
        <th
          width="20%"
          class="hand"
          @click="sortBy('contacts.client_lead')"
          :class="getSortClass('contacts.client_lead')"
        >
          Lead
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="contact in resultList"
        :key="contact.id"
        class="hand"
        @click="getContact(contact.id)"
      >
        <td>{{ contact.industry }}</td>
        <td>{{ contact.company }}</td>
        <td>{{ contact.first_name }} {{ contact.last_name }}</td>
        <td>{{ contact.city }} {{ contact.state }}</td>
        <td>{{ contact.client_lead }}</td>
      </tr>
    </tbody>
  </table>

  <!-- drawer ********************************************************** -->
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="drawerDiv"
    aria-labelledby="drawerDivLabel"
    data-bs-keyboard="false"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="drawerDivLabel"></h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body">
      <div class="d-flex align-items-center mb-3">
        <div class="ms-auto">
          <div v-if="editMode">
            <button class="btn btn-light btn-sm mx-3" @click="viewContact">
              Cancel
            </button>
            <button
              class="btn btn-primary btn-sm"
              @click="saveContact(false)"
              :disabled="isLoading"
            >
              Save
            </button>
          </div>
          <div v-else>
            <button
              class="btn btn-secondary btn-sm me-3"
              @click="deleteContact"
            >
              Delete
            </button>
            <button class="btn btn-primary btn-sm" @click="editContact">
              Edit
            </button>
          </div>
        </div>
      </div>

      <!-- Duplicate contact found ************************************************************************************* -->
      <div v-if="duplicateRecord" class="alert alert-danger mb-3" role="alert">
        Duplicate contact found. <br />
        {{ duplicateRecord.first_name }}
        {{ duplicateRecord.last_name }} <br />
        {{ duplicateRecord.company }} <br />
        <button
          class="btn btn-secondary btn-sm me-3"
          @click="getContact(duplicateRecord.id)"
        >
          View Contact
        </button>

        <button class="btn btn-primary btn-sm" @click="saveContact(true)">
          Save Anyway
        </button>
      </div>
      <div
        v-if="errorMessage.length > 0"
        class="alert alert-danger mb-3"
        role="alert"
        v-html="errorMessage"
      ></div>
      <div
        v-if="successMessage.length > 0"
        class="alert alert-success mb-3"
        role="alert"
        v-html="successMessage"
      ></div>

      <!-- form ************************************************************************************* -->
      <form>
        <div class="card-title">Personal Info</div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="mb-3">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">First Name</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      :class="classFirstName"
                      v-model="contact.first_name"
                    />
                  </div>
                  <div v-else>{{ contact.first_name }}</div>
                </div>
                <div class="col-6">
                  <label class="form-label">Last Name</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      :class="classLastName"
                      v-model="contact.last_name"
                    />
                  </div>
                  <div v-else>{{ contact.last_name }}</div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="row">
                <div class="col-6">
                  <label class="form-label">Email</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      :class="classEmail"
                      v-model="contact.email"
                    />
                  </div>
                  <div v-else>{{ contact.email }}</div>
                </div>
                <div class="col-6">
                  <label class="form-label">LinkedIn URL</label>
                  <div v-if="editMode">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="contact.linkedin_url"
                    />
                  </div>
                  <div v-else>{{ contact.linkedin_url }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-title">Company Info</div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Industry</label>
                <div v-if="editMode">
                  <select
                    v-model="industrySelect"
                    class="form-select form-select-sm"
                    @change="industrySelectChange()"
                  >
                    <option value="">other</option>
                    <option
                      v-for="industry in helpers.getIndustries()"
                      :key="industry"
                      :value="industry"
                    >
                      {{ industry }}
                    </option>
                  </select>

                  <input
                    v-if="showIndustryInput"
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.industry"
                    placeholder="industry"
                  />
                </div>
                <div v-else>{{ contact.industry }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Company</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    :class="classCompany"
                    v-model="contact.company"
                    @change="validate()"
                  />
                </div>
                <div v-else>{{ contact.company }}</div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Title</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.title"
                  />
                </div>
                <div v-else>{{ contact.title }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Role</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.role"
                  />
                </div>
                <div v-else>{{ contact.role }}</div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Salutation</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.salutation"
                  />
                </div>
                <div v-else>{{ contact.salutation }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Seniority</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.seniority"
                  />
                </div>
                <div v-else>{{ contact.seniority }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Branch</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.branch"
                  />
                </div>
                <div v-else>{{ contact.branch }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Center</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.center"
                  />
                </div>
                <div v-else>{{ contact.center }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Location -->
        <div class="card-title">Location</div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="mb-3">
              <div v-if="editMode">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="contact.address1"
                  placeholder="address"
                />
              </div>
              <div v-else>{{ contact.address1 }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-4">
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.city"
                    placeholder="city"
                  />
                </div>
                <div v-else>{{ contact.city }}</div>
              </div>
              <div class="col-4">
                <div v-if="editMode && contact.country == 'United States'">
                  <select
                    v-model="contact.state"
                    class="form-select form-select-sm"
                  >
                    <option
                      v-for="state in helpers.getStates()"
                      :key="state"
                      :value="state"
                    >
                      {{ state }}
                    </option>
                  </select>
                </div>
                <div v-else-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.state"
                    placeholder="state"
                  />
                </div>
                <div v-else>{{ contact.state }}</div>
              </div>
              <div class="col-4">
                <div v-if="editMode">
                  <select
                    v-model="contact.country"
                    class="form-select form-select-sm"
                  >
                    <option value=""></option>
                    <option
                      v-for="country in helpers.getCountries()"
                      :key="country"
                      :value="country"
                    >
                      {{ country }}
                    </option>
                  </select>
                </div>
                <div v-else>{{ contact.country }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Ipsos Internal -->
        <div class="card-title">Ipsos Internal</div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Updated At</label>
                <div>{{ getFormattedDate(contact.updated_at) }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Current Client</label>
                <div v-if="editMode">
                  <select
                    v-model="contact.client_status"
                    class="form-select form-select-sm"
                  >
                    <option value=""></option>
                    <option
                      v-for="clientStatus in helpers.getClientStatus()"
                      :key="clientStatus"
                      :value="clientStatus"
                    >
                      {{ clientStatus }}
                    </option>
                  </select>
                </div>
                <div v-else>{{ contact.client_status }}</div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Source</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="contact.source"
                  />
                </div>
                <div v-else>{{ contact.source }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">Language</label>
                <div v-if="editMode">
                  <select
                    v-model="contact.language"
                    class="form-select form-select-sm"
                  >
                    <option value=""></option>
                    <option
                      v-for="language in helpers.getLanguages()"
                      :key="language[1]"
                      :value="language[0]"
                    >
                      {{ language[0] }}
                    </option>
                  </select>
                </div>
                <div v-else>{{ contact.language }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label">Origin</label>
                <div v-if="editMode">
                  <input
                    type="text"
                    :class="classOrigin"
                    v-model="contact.origin"
                  />
                </div>
                <div v-else>{{ contact.origin }}</div>
              </div>
              <div class="col-6">
                <label class="form-label">ClientLead</label>
                <div>{{ contact.client_lead }}</div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Notes</label>
              <div v-if="editMode">
                <textarea
                  class="form-control"
                  v-model="contact.notes"
                ></textarea>
              </div>
              <div v-else>{{ contact.notes }}</div>
            </div>
          </div>
        </div>
      </form>
      <!-- edit mode ************************************************************************************* -->
    </div>
  </div>
</template>
