export default {
    getErrorMessage(error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.detail
        ) {
            // if array
            if (Array.isArray(error.response.data.detail)) {
                return error.response.data.detail.join("<br>");
            } else {
                return error.response.data.detail;
            }
        } else {
            return error.message;
        }
    },
    getHelperSortClass(column, filters) {
        return filters.sort_column === column
            ? filters.sort_order === "asc"
                ? "hand bi bi-caret-down-fill"
                : "hand bi bi-caret-up-fill"
            : "hand ";
    },
    getStates() {
        return [
            "Alaska",
            "Alabama",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada", 
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    getCountries() {
        return [
            "Afghanistan",
            "Albania",
            "Algeria",
            "Andorra",
            "Angola",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Brazil",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Colombia",
            "Comoros",
            "Congo",
            "Costa Rica",
            "Cote d'Ivoire",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czech Republic",
            "Democratic Republic of the Congo",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Eswatini",
            "Ethiopia",
            "Fiji",
            "Finland",
            "France",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Greece",
            "Grenada",
            "Guatemala",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Honduras",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Israel",
            "Italy",
            "Jamaica",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Mauritania",
            "Mauritius",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Morocco",
            "Mozambique",
            "Myanmar",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "North Korea",
            "North Macedonia",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Poland",
            "Portugal",
            "Qatar",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Korea",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Sweden",
            "Switzerland",
            "Syria",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "United States",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Vatican City",
            "Venezuela",
            "Vietnam",
            "Yemen",
            "Zambia",
            "Zimbabwe",
          ]
    },
    getClientStatus() {
        return [
            "client",
            "other"]
    },
    getIndustries() {
        return [
            "Advertising",
            "Aerospace/Defense",
            "Automotive",
            "Banking",
            "Capital Goods",
            "Chemicals",
            "Commercial Services & Supplies",
            "Conglomerates",
            "Construction",
            "Consumer Durables",
            "Diversified Financials",
            "Drugs & Biotechnology",
            "Food Drink & Tobacco",
            "Food Markets",
            "Health Care Equipment & Services",
            "Hotels, Restaurants & Leisure",
            "Household & Personal Products",
            "Insurance",
            "Luxury",
            "Management Consulting",
            "Market Research",
            "Materials",
            "Media",
            "Oil & Gas Operations",
            "Public Institutions/Government",
            "Retailing",
            "Semiconductors",
            "Software & Services",
            "Technology Hardware & Equipment",
            "Telecommunications Services",
            "Trading Companies",
            "Transportation",
            "Utilities"
        ]
    },
    getLanguages() {
        return [
            ["Abkhazian", "ab"],
            ["Afar", "aa"],
            ["Afrikaans", "af"],
            ["Akan", "ak"],
            ["Albanian", "sq"],
            ["Amharic", "am"],
            ["Arabic", "ar"],
            ["Aragonese", "an"],
            ["Armenian", "hy"],
            ["Assamese", "as"],
            ["Avaric", "av"],
            ["Avestan", "ae"],
            ["Aymara", "ay"],
            ["Azerbaijani", "az"],
            ["Bambara", "bm"],
            ["Bashkir", "ba"],
            ["Basque", "eu"],
            ["Belarusian", "be"],
            ["Bengali (Bangla)", "bn"],
            ["Bihari", "bh"],
            ["Bislama", "bi"],
            ["Bosnian", "bs"],
            ["Breton", "br"],
            ["Bulgarian", "bg"],
            ["Burmese", "my"],
            ["Catalan", "ca"],
            ["Chamorro", "ch"],
            ["Chechen", "ce"],
            ["Chichewa, Chewa, Nyanja", "ny"],
            ["Chinese", "zh"],
            ["Chinese (Simplified)", "zh-Hans"],
            ["Chinese (Traditional)", "zh-Hant"],
            ["Chuvash", "cv"],
            ["Cornish", "kw"],
            ["Corsican", "co"],
            ["Cree", "cr"],
            ["Croatian", "hr"],
            ["Czech", "cs"],
            ["Danish", "da"],
            ["Divehi, Dhivehi, Maldivian", "dv"],
            ["Dutch", "nl"],
            ["Dzongkha", "dz"],
            ["English", "en"],
            ["Esperanto", "eo"],
            ["Estonian", "et"],
            ["Ewe", "ee"],
            ["Faroese", "fo"],
            ["Fijian", "fj"],
            ["Finnish", "fi"],
            ["French", "fr"],
            ["Fula, Fulah, Pulaar, Pular", "ff"],
            ["Galician", "gl"],
            ["Gaelic (Scottish)", "gd"],
            ["Gaelic (Manx)", "gv"],
            ["Georgian", "ka"],
            ["German", "de"],
            ["Greek", "el"],
            ["Greenlandic", "kl"],
            ["Guarani", "gn"],
            ["Gujarati", "gu"],
            ["Haitian Creole", "ht"],
            ["Hausa", "ha"],
            ["Hebrew", "he"],
            ["Herero", "hz"],
            ["Hindi", "hi"],
            ["Hiri Motu", "ho"],
            ["Hungarian", "hu"],
            ["Icelandic", "is"],
            ["Ido", "io"],
            ["Igbo", "ig"],
            ["Indonesian", "id, in"],
            ["Interlingua", "ia"],
            ["Interlingue", "ie"],
            ["Inuktitut", "iu"],
            ["Inupiak", "ik"],
            ["Irish", "ga"],
            ["Italian", "it"],
            ["Japanese", "ja"],
            ["Javanese", "jv"],
            ["Kalaallisut, Greenlandic", "kl"],
            ["Kannada", "kn"],
            ["Kanuri", "kr"],
            ["Kashmiri", "ks"],
            ["Kazakh", "kk"],
            ["Khmer", "km"],
            ["Kikuyu", "ki"],
            ["Kinyarwanda (Rwanda)", "rw"],
            ["Kirundi", "rn"],
            ["Kyrgyz", "ky"],
            ["Komi", "kv"],
            ["Kongo", "kg"],
            ["Korean", "ko"],
            ["Kurdish", "ku"],
            ["Kwanyama", "kj"],
            ["Lao", "lo"],
            ["Latin", "la"],
            ["Latvian (Lettish)", "lv"],
            ["Limburgish ( Limburger)", "li"],
            ["Lingala", "ln"],
            ["Lithuanian", "lt"],
            ["Luga-Katanga", "lu"],
            ["Luganda, Ganda", "lg"],
            ["Luxembourgish", "lb"],
            ["Manx", "gv"],
            ["Macedonian", "mk"],
            ["Malagasy", "mg"],
            ["Malay", "ms"],
            ["Malayalam", "ml"],
            ["Maltese", "mt"],
            ["Maori", "mi"],
            ["Marathi", "mr"],
            ["Marshallese", "mh"],
            ["Moldavian", "mo"],
            ["Mongolian", "mn"],
            ["Nauru", "na"],
            ["Navajo", "nv"],
            ["Ndonga", "ng"],
            ["Northern Ndebele", "nd"],
            ["Nepali", "ne"],
            ["Norwegian", "no"],
            ["Norwegian bokmål", "nb"],
            ["Norwegian nynorsk", "nn"],
            ["Nuosu", "ii"],
            ["Occitan", "oc"],
            ["Ojibwe", "oj"],
            ["Old Church Slavonic, Old Bulgarian", "cu"],
            ["Oriya", "or"],
            ["Oromo (Afaan Oromo)", "om"],
            ["Ossetian", "os"],
            ["Pāli", "pi"],
            ["Pashto, Pushto", "ps"],
            ["Persian (Farsi)", "fa"],
            ["Polish", "pl"],
            ["Portuguese", "pt"],
            ["Punjabi (Eastern)", "pa"],
            ["Quechua", "qu"],
            ["Romansh", "rm"],
            ["Romanian", "ro"],
            ["Russian", "ru"],
            ["Sami", "se"],
            ["Samoan", "sm"],
            ["Sango", "sg"],
            ["Sanskrit", "sa"],
            ["Serbian", "sr"],
            ["Serbo-Croatian", "sh"],
            ["Sesotho", "st"],
            ["Setswana", "tn"],
            ["Shona", "sn"],
            ["Sichuan Yi", "ii"],
            ["Sindhi", "sd"],
            ["Sinhalese", "si"],
            ["Siswati", "ss"],
            ["Slovak", "sk"],
            ["Slovenian", "sl"],
            ["Somali", "so"],
            ["Southern Ndebele", "nr"],
            ["Spanish", "es"],
            ["Sundanese", "su"],
            ["Swahili (Kiswahili)", "sw"],
            ["Swati", "ss"],
            ["Swedish", "sv"],
            ["Tagalog", "tl"],
            ["Tahitian", "ty"],
            ["Tajik", "tg"],
            ["Tamil", "ta"],
            ["Tatar", "tt"],
            ["Telugu", "te"],
            ["Thai", "th"],
            ["Tibetan", "bo"],
            ["Tigrinya", "ti"],
            ["Tonga", "to"],
            ["Tsonga", "ts"],
            ["Turkish", "tr"],
            ["Turkmen", "tk"],
            ["Twi", "tw"],
            ["Uyghur", "ug"],
            ["Ukrainian", "uk"],
            ["Urdu", "ur"],
            ["Uzbek", "uz"],
            ["Venda", "ve"],
            ["Vietnamese", "vi"],
            ["Volapük", "vo"],
            ["Wallon", "wa"],
            ["Welsh", "cy"],
            ["Wolof", "wo"],
            ["Western Frisian", "fy"],
            ["Xhosa", "xh"],
            ["Yiddish", "yi, ji"],
            ["Yoruba", "yo"],
            ["Zhuang, Chuang", "za"],
            ["Zulu", "zu"],
          ]
    }
  };